.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px;
    background-color: var(--footer-color);
    color: var(--light-color);
}

.footer .text {
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
}

.footer .text>a {
    text-decoration: none;
    color: var(--light-color);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.footer .text>a:hover {
    color: var(--green-color);
    letter-spacing: 1.2px;
}